<template>
  <div class="user-list-page">
    <en-table-layout :tableData="tableData.data">
      <template slot="toolbar">
        <div class="col toolbar-title">游客列表<span
            style="font-size: 12px;color:red;margin-left:5px">指只登录移动端前台，但未绑定手机号的用户群体。</span></div>

        <div class="col-12">
          <div class="row no-gutters align-items-center pt-3">
            <el-form-item label="注册卡来源">
              <el-select style="width: 100px" v-model="inform.card_type" size="medium" placeholder="请选择" clearable>
                <el-option label="全部" value></el-option>
                <el-option label="计次卡" value="6"></el-option>
                <el-option label="宅配卡" value="7"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="注册时间">
              <el-date-picker style="width: 260px" v-model="time" size="medium" type="daterange"
                @change="daterangeDateChange" start-placeholder="开始日期" end-placeholder="结束日期"
                :default-time="['00:00:00']" />
            </el-form-item>
            <el-form-item>
              <el-input size="medium" style="width: 180px" v-model.trim="inform.card_code" placeholder="请输入卡号进行搜索"
                clearable></el-input>
            </el-form-item>
            <el-button @click="seachMember" size="small" type="primary">搜索</el-button>
          </div>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column label="用户ID" width="80">
          <template slot-scope="scope">{{ scope.row.tourist_id }}</template>
        </el-table-column>
        <el-table-column prop="nickname" label="注册卡来源" width="120">
          <template slot-scope="scope">{{ scope.row.card_type == 6 ? '计次卡' : '宅配卡' }}</template>
        </el-table-column>
        <el-table-column prop="mobile" label="注册卡号">
          <template slot-scope="scope">{{ scope.row.card_code }}</template>
        </el-table-column>
        <el-table-column prop="name" label="注册时间" min-width="110">
          <template slot-scope="scope">{{ scope.row.create_date | unixToDate }}</template>
        </el-table-column>
        <el-table-column prop="login_ip" label="IP地址" min-width="150">
          <template slot-scope="scope">
            {{ scope.row.login_ip }}
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="inform.page_no" :page-sizes="MixinPageSizes" background :layout="MixinTableLayout"
        :page-size="inform.page_size" :total="total" />
    </en-table-layout>
  </div>
</template>

<script>
import * as API_Users from "@/api/users";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";

export default {
  components: {
    EnTableLayout
  },
  data () {
    return {
      time: "",
      total: 0,
      inform: {
        card_code: "",
        card_type: "",
        page_size: 20,
        page_no: 1,
      },
      tableData: {}
    };
  },
  created () {
    this.getList();
  },
  methods: {
    daterangeDateChange (val) {//时间格式化
      this.inform.start_time = val ? parseInt(val[0] / 1000) : "";
      this.inform.end_time = val ? parseInt(val[1] / 1000) + 86399 : "";
    },
    //搜索
    seachMember () {
      this.getList(1);
    },
    //查询列表数据
    getList (page_no) {
      const params = {
        ...this.inform
      }
      page_no && (params.page_no = 1);
      API_Users.touristList(params).then(res => {
        this.total = res.data_total;
        this.page_size = res.page_size;
        this.page_no = res.page_no;
        this.tableData = res;
      });
    },
    /**分页大小发生改变 */
    handleSizeChange (val) {
      this.inform.page_size = val;
      this.getList();
    },
    /**跳转指定页数 */
    handleCurrentChange (val) {
      this.inform.page_no = val;
      this.getList();
    }
  }
};
</script>
<style scoped lang="scss">
.userData {
  margin-top: 5px;
}

/deep/ .el-form-item__label {
  font-size: 12px;
}

/deep/ .el-input__inner {
  font-size: 12px;
}

/deep/ .el-range-input {
  font-size: 12px;
}

/deep/ .el-date-editor--datetimerange.el-input__inner {
  width: 168px;
}
</style>